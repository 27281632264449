<template>
  <b-row no-gutters class="author-tab align-items-center">
    <b-col class="author-tab__container-image img-fill-height">
      <b-img @click="toProfileOwner" :src="author.image" class="img-fill-height"/>
    </b-col>
    <div class="d-flex flex-column author-tab__container-info ml-2 mr-4">
      <router-link 
      :to="`/@${author.username}`"
      :class="lightMode ? 'f-color-light' : 'f-color-dark' ">
        {{ author.username }}
      </router-link>
      <p class="m-0">{{ article.createdAt | transformInDate }}</p>
    </div>
    <span v-for="button in buttons"
    :key="button.id">
      <b-button 
      v-if="button.showWhen() && userHasLogged"
      size="sm"
      :disabled="isLoading"
      class="ml-1"
      @click="button.action"
      :variant="button.variant">
        <b-icon :icon="button.icon" class="mr-2"/>{{ button.text }}
      </b-button>
    </span>
  </b-row>
</template>

<script>
export default {
  props: {
    article: {
      type: Object,
      required: true
    },
    hasFollowingOwner: {
      type: Boolean,
      required: true
    },
    hasFavoriteArticle: {
      type: Boolean,
      required: true
    },
    lightMode: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  async beforeMount () {
    if (this.userHasLogged) {
      this.updateLastInfoUser()
    }
    const profileOwner = await this.$http.getProfile(this.author.username)
    .then( ({ data }) => data.profile.following)
    this.hasFollowingOwnerModel = profileOwner
  },
  computed: {
    author () {
      return this.article.author
    },
    hasOwner () {
      return this.username === this.author.username
    },
    username () {
      return this.getStore('user/data', 'username')
    },
    hasFollowingOwnerModel: {
      get () {
        return this.hasFollowingOwner
      },
      set (value) {
        this.$emit('setHasFollowingOwner', value)
      }
    },
    hasFavoriteArticleModel: {
      get () {
        return this.hasFavoriteArticle
      },
      set (value) {
        this.$emit('setHasFavoriteArticle', value)
      }
    }
  },
  data () {
    return {
      isLoading: false,
      buttons: [
        {
          id: 'editArticle',
          variant: 'outline-secondary',
          icon: 'pencil-fill',
          text: 'Edit Article',
          showWhen: () => this.hasOwner,
          action: this.toEditArticle
        },
        {
          id: 'deleteArticle',
          variant: 'outline-danger',
          icon: 'trash',
          text: 'Delete Article',
          showWhen: () => this.hasOwner,
          action: this.deleteArticle
        },
        {
          id: 'followingOwner',
          variant: 'outline-secondary',
          icon: 'plus',
          text: `Following ${this.article.author.username}`,
          showWhen: () => !this.hasOwner && !this.hasFollowingOwnerModel,
          action: this.followingOwner
        },
        {
          id: 'unfollowingOwner',
          variant: 'secondary',
          icon: 'plus',
          text: `Unfollowing ${this.article.author.username}`,
          showWhen: () => !this.hasOwner && this.hasFollowingOwnerModel,
          action: this.unfollowingOwner
        },
        {
          id: 'favoriteArticle',
          variant: 'outline-success',
          icon: 'heart',
          text: 'Favorite article',
          showWhen: () => !this.hasOwner && !this.hasFavoriteArticleModel,
          action: this.favoriteArticle
        },
        {
          id: 'unfavoriteArticle',
          variant: 'success',
          icon: 'heart',
          text: 'Unfavorite article',
          showWhen: () => !this.hasOwner && this.hasFavoriteArticleModel,
          action: this.unfavoriteArticle
        }
      ]
    }
  },
  methods: {
    toProfileOwner () {
      this.$router.push(`/@${this.author.username}`)
    },
    toEditArticle () {
      this.$router.push(`/editor/${this.article.slug}`)
    },
    async deleteArticle () {
      this.isLoading = true
      await this.$http.deleteArticle(this.article.slug)
      this.$router.push(`/@${this.username}`)
    },
    async followingOwner () {
      this.isLoading = true
      await this.$http.followingProfile(this.author.username)
        .then( ({ data }) => {
          this.hasFollowingOwnerModel = data.profile.following
        }).finally( () => {
          this.isLoading = false
        })
    },
    async unfollowingOwner () {
      this.isLoading = true
      await this.$http.unfollowingProfile(this.author.username)
        .then( ({ data }) => {
          this.hasFollowingOwnerModel = data.profile.following
        }).finally( () => {
          this.isLoading = false
        })
    },
    async favoriteArticle () {
      this.isLoading = true
      await this.$http.favoriteArticle(this.article.slug)
        .then( ({ data }) => {
          this.hasFavoriteArticleModel = data.article.favorited
        }).finally( () => {
          this.isLoading = false
        })
    },
    async unfavoriteArticle () {
      this.isLoading = true
      await this.$http.unfavoriteArticle(this.article.slug)
        .then( ({ data }) => {
          this.hasFavoriteArticleModel = data.article.favorited
        }).finally( () => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss">
  .author-tab {
    height: 40px;
    &__container-image {
      img {
        border-radius: 50%;
        object-fit: cover;
        height: 32px;
        width: 32px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    &__container-info {
      a {
        line-height: 1.3;
        &:hover {
          color: $light;
        }
      }
      p {
        line-height: 1.3;
        font-size: .8rem;
      }
    }
  }
</style>