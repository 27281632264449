<template>
  <b-row class="article">
    <b-col class="article__header pt-4 pb-3" cols=12 v-if="article">
      <b-container class="d-flex flex-column align-items-start">
        <h1 class="f-bold f-color-light mb-4">{{ article.title }}</h1>
        <AppArticleAuthorTab 
        :hasFavoriteArticle="hasFavoriteArticle"
        @setHasFavoriteArticle="hasFavoriteArticle = $event"
        :hasFollowingOwner="hasFollowingOwner"
        @setHasFollowingOwner="hasFollowingOwner = $event"
        :article="article"/>
        <p>{{ article.bio }}</p>
      </b-container>
    </b-col>
    <b-container v-if="article">
      <b-row>
        <b-col cols=12 
        class="article__description py-5 text-left" 
        tag="p">
          {{ article.body }}
        </b-col>
        <b-col cols=12 class="d-flex justify-content-center mb-4">
          <AppArticleAuthorTab 
          :hasFavoriteArticle="hasFavoriteArticle"
          @setHasFavoriteArticle="hasFavoriteArticle = $event"
          :hasFollowingOwner="hasFollowingOwner"
          @setHasFollowingOwner="hasFollowingOwner = $event"
          :article="article" 
          :lightMode="false"/>
        </b-col>
        <b-col cols=6 offset=3>

          <AppWriteComment
          v-if="userHasLogged"
          :isLoading="isLoading"
          @addComment="addComment"/>

          <AppComment v-for="comment in comments"
          class="my-4"
          :isLoading="isLoading"
          @removeComment="removeComment"
          :key="comment.id"
          :slug="article.slug"
          :comment="comment"/>
        </b-col>
      </b-row>
    </b-container>
  </b-row>
</template>

<script>
import AppArticleAuthorTab from '@/components/Article/AuthorTab.vue'
import AppWriteComment from '@/components/Article/WriteComment.vue'
import AppComment from '@/components/Article/Comment.vue'

export default {
  components: {
    AppArticleAuthorTab,
    AppWriteComment,
    AppComment
  },
  metaInfo () {
    return {
      title: `${this.article?.title || 'Artigo'} | test-almocogratis`
    }
  },
  async beforeMount () {
    await this.$http.getArticle(this.slugArticle)
      .then( ({ data }) => {
        this.article = data.article
        this.hasFollowingOwner = data.article.author.following
        this.hasFavoriteArticle = data.article.favorited
      })
    await this.$http.getCommentsArticle(this.slugArticle)
      .then( ({ data }) => {
        this.comments = data.comments.sort((a, b) => a.createdAt - b.createdAt)
      })
  },
  data () {
    return {
      isLoading: false,

      hasFollowingOwner: undefined,
      hasFavoriteArticle: undefined,

      article: undefined,
      comments: undefined
    }
  },
  computed: {
    slugArticle () {
      return this.$route.params.slug_article
    }
  },
  methods: {
    async addComment (comment) {
      this.isLoading = true
      await this.$http.createComment(this.article.slug, {
        comment: {
          body: comment
        }
      }).then( ({ data }) => {
        this.comments.push(data.comment)
      }).finally( () => {
        this.isLoading = false
      })
    },
    async removeComment (commentId) {
      this.isLoading = true
      await this.$http.deleteCommentArticle(this.article.slug, commentId)
        .then( () => {
          const indexRemove = this.comments.findIndex(el => el.id === commentId)
          this.$delete(this.comments, indexRemove )
        }).finally( () => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style lang="scss">
  .article {
    &__header {
      background-color: lighten($dark, 20%);
      &__container-img {
        height: 100px;
        img {
          border-radius: 50%;
          width: 100px;
          object-fit: cover;
          height: 100px;
        }
      }
      p {
        max-width: 450px;
        width: 100%;
        color: lighten($dark, 70%);
      }
    }
    &__description {
      border-bottom: 1px solid lighten(black, 90%);
    }
  }
</style>