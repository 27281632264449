<template>
  <section class="comment">
    <div class="comment__top p-3">
      <p class="m-0 text-left"> {{ comment.body }}</p>
    </div>
    <b-row no-gutters class="comment__bottom py-2 px-3 align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <b-img :src="comment.author.image" class="img-fill-height mr-3"/>
        <router-link :to="`/@${comment.author.username}`"
        class="f-color-2 mr-3 mb-0 f-color-dark">
          {{ comment.author.username }}
        </router-link>
        <p class="m-0">{{ comment.createdAt | transformInDate }}</p>
      </div>
      <b-button
      v-if="userHasLogged"
      @click="$emit('removeComment', comment.id)"
      pill
      :disabled="isLoading"
      variant="outline-secondary"
      class="p-0 button-delete">
        <b-icon icon="trash"/>
      </b-button>
    </b-row >
  </section>
</template>

<script>
export default {
  props: {
    comment: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss">
  .comment {
    &__top {
      background-color: lighten($dark, 90%);
    }
    &__bottom {
      height: 50px;
      background-color: lighten($dark, 80%);
      img {
        height: 30px;
        border-radius: 50%;
        width: 30px;
        object-fit: cover;
      }
      svg {
        cursor: pointer;
      }
      .button-delete {
        border: none !important;
        background-color: transparent !important;
        box-shadow: none !important;
      }
    }
  }
</style>