<template>
  <b-form 
  @submit.stop.prevent="$emit('addComment', comment); comment = undefined"
  class="write-comment">
    <b-form-textarea
    v-model="comment"
    placeholder="Write a comment..."/>
    <div class="write-comment__bottom d-flex justify-content-end align-itens-center">
      <b-button
      :disabled="isLoading || comment === null || comment === undefined || comment.length === 0"
      size="sm"
      type="submit"
      variant="success">
        Post Comment
      </b-button>
    </div>
  </b-form>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      comment: undefined
    }
  },
  computed: {
    user () {
      return this.getStore('user/data')
    }
  }
}
</script>

<style lang="scss">
.write-comment {
  &__bottom {
    width: 100%;
    height: 50px;
    background-color: lighten($dark, 90%);
  }
}
</style>